@mixin laptop {
  @media (max-width: 1300px) {
    @content;
  }
}

@mixin pad {
  @media (max-width: 768px) {
    @content;
  }
}



* {
  margin: 0;
  padding: 0;
  font-family: 'Noto Serif TC', serif;
  box-sizing: border-box;
  transition: all 0.3s;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: #555;
}

header {
  background: linear-gradient(#f3f1da6a,#ffffffc2);
  border-bottom: 1px solid #fff;
  backdrop-filter: blur(5px);
  color: #555;
  position: fixed;
  top: 0;
  padding: 0.6rem;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;  
  flex-wrap: wrap;
  z-index: 5;
  @include pad{
    padding: 0.6rem;
  }
  
  img.logoIMG {
    width: 140px;
  } 
  .navBuger{
    width: 30px;
    height: 30px;    
    border-radius: 5px;
    display: none;
    @include pad{
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(../../public/img/burgerICON.png);
      &.active{
        background-image: url(../../public/img/crossICON.png);
        background-color: #93945C;
      }
    }   
    
  } 
  nav{
    margin-left: 30px; 

    @include pad{
      margin-left: 0;
    }
     
    &.hidden{
      @include pad{      
        display: none;
     }
    } 
    ul{
      display: flex;
      @include pad{
        width: 100vw;      
        display: block;
     }     
    }
      
      li{
        padding: 10px 20px;
        margin: 10px;
        @include pad{
          margin: 0;
        }
        a{
          font-family: 'Noto sans TC';
          font-weight: 500;          
          color: #868737;
          letter-spacing: 0.05rem;
          @include pad{
            display: block;
            text-align: center;
          }
        }        
      }
    }
}


footer{ 
    display: flex; 
    color: #fff;
    align-items: center;
    justify-content: space-between;
    @include laptop{
      flex-direction: column;      
    }

    .copyrightBlock{       
      width: 65%; 
      display: flex;
      justify-content:space-around; 
      align-items:center;      
      
      @include laptop{
        padding-top: 50px;
        width: 100%;        
        flex-direction: column;        
        justify-content:center;        
      }      
      .mountainIMG{
        width: 55vw;
        position: absolute;
        right: 0;
        bottom: 0;
        @include laptop{
          width: 100%;
          height: 100px;
        }
      }
      h3{
        position: absolute; 
        bottom: 40px;
        right:35vw; 
        @include laptop{
          font-size: 16px;
          bottom: 10px;
          left:50px;
          right:auto;
        }      
      }
  }
  ul{
    display: flex; 
    font-size: 14px;   
    
    &.storeInfo{
      width: clamp(430px,35%,600px);      
      justify-content: center;
      flex-wrap: wrap;
      line-height: 28px;
      padding: 40px 0px;
      @include pad{
        justify-content: flex-start;
        width:100%;        
      }
      li{
        margin: 0px 20px;
        @include pad{
          width:100%;
          font-size:0.9rem;
          margin: 0 10px;
        }
        i{
          margin-right: 5px;
        }
      }
    }
    &.SNSicons{
      position: absolute;
      bottom: 40px;
      right:10vw;
      @include laptop{
        right:50px;
        bottom: 10px;
      }
      li{
        margin: 5px;
        img{
          width:30px;
          @include laptop{
            width:20px;
          }
        }
      }
    }
  }
}



section.menu_wrapper{
  display: flex;
  justify-content: center;    
  min-height: 80vh;
  @include pad{
    flex-direction: column;
  }
  
  .attention{
    width: 200px;
    padding: 20px 15px;
    background-color: rgb(155, 41, 41);    
    margin-top: 60px;
    text-align: center;
    letter-spacing: 1px;
    @include pad{
      margin-top: 0;
      width: 100%;
    }
    a{
      color: #fff;
    }
  }
}
.construct_wrapper{
  display: flex;
  justify-content: center;    
  min-height: 80vh;
  align-items: center;
  font-size: 26px;
  padding: 20px;
  letter-spacing: 5px;
}

.menu_tabs{
  align-items: center;
  margin-top: 20px;
  @include pad{
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin-top: 0;
  }
  li{
    font-size: 1.1rem;
    letter-spacing: 2px;    
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #656539;
    color: #656539;
    background: #ebecb9;
    @include pad{
      width: calc(100% / 9);
      writing-mode: vertical-lr;
      display: flex;
      align-items: center;
      line-height: 1.2rem;
      align-self: stretch;
      font-size: 1rem;
      padding:15px;
    }
    &::before{
      content: '－';
      display: inline-block;
      width: 18px;
      line-height: 18px;
      padding: 3px;
      border-radius: 5px;      
      color: #fff;      
      background: rgb(155, 41, 41);
      margin-right: 10px;
      @include pad{
        margin-right: 0;
        margin-bottom: 5px;
        writing-mode: horizontal-tb;
      }
    }
    &.minus{
      background: #FEFCE8;
      &::before{
        content: '＋';
        background: rgb(92, 133, 74);  
      }
    }
  }
}
.menu-content{
  width: clamp(800px,90%, 900px);
  padding: 70px;
  display: flex;
  flex-direction: column;
  @include pad{
    width: 100%;
    padding: 20px;
  }
  
  .food-type-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;  
    h3.food-type{
      width:100%;
      border:4px double rgb(155, 41, 41);
      color: rgb(155, 41, 41);
      padding: 10px;
      text-align: center;
      letter-spacing: 4px;
      @include pad{
        padding: 5px;
        font-size: 1.1rem;
        letter-spacing: 2px;
      }
    }
  }
  .menu-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    @include pad{
      
    } 
    h4{
      writing-mode: vertical-lr;      
    }   
  }
}



body {  
  font-family: "Noto Sans TC", sans-serif;
  min-height: 100vh;  
  width: 100%;
  overflow-x: hidden;
  background: linear-gradient(#F3F1DA, #FEFCE8);
  position: relative;
  padding-top: 70px;
 
  img.headerleaf1{
    position: absolute;
    top:0px;
    left: 40%;
    height: 60px;
    @include pad{
      left: -20px;
      top:70px;
      height: 40px;
    }
   }
   img.headerleaf2{
    position: absolute;
    top:0px;
    right: 0px;
    height:90px;
   }

  h1.slogan{
    color:#656539;
    font-size: 32px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0.2rem;
    align-self: center;
    @include laptop{
      font-size: 28px;
      width: clamp(320px,40%,360px);
    }
    @include pad{
      width: 100%;  
      text-align: center;    
      font-size: 24px;
      line-height: 36px;      
    }
  }   
}

.slick-slider{
  padding: 0px 0px 10px;  
  width: clamp(300px,80%,1040px);
  display: flex;
  align-items: center;
  margin: 0 auto;
  @include laptop{ 
    width:780px;  
    margin-top: 50px;
  }
  @include pad{    
    width: 300px;
  }
  .slick-dots{
    bottom: -10px;
    li{
      margin: 0;
    }
  }  
  .slick-arrow{
    align-self: center;
    top:auto;
    &::before{
      color: #656539;
    }
  }
  img{
    width: 330px;
    @include laptop{
      width:250px;
    }  
    @include pad{
      width:300px;
    }    
  }
}
section.horizonBlock3{
  background-image: url('../../public/img/background-brush2.png');
  background-size:clamp(1200px,85%,1600px);
  background-repeat: no-repeat;
  background-position:center 30px ;
  display: flex;  
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 80px;

  @include pad{
    width: 100%;  
    padding-bottom: 50px;  
  }
  div.topWrap{
    display: flex;
    justify-content: center;
    padding: 100px 0px;
    flex-wrap: wrap;    

    @include laptop{
      width: 100%;  
      padding:30px 0px;   
      
    }
  }
  section.hachastory{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: clamp(300px,100%,400px);    
    margin:30px 50px ;
    @include pad{
      padding: 20px;
      margin: 40px 20px;
    }
    p{
      font-family: 'Noto Sans TC';
      line-height: 28px;
    }
  }
  
  .map{
    display: flex;
    flex-direction: column;
    align-items: center;
   
    h2.h2Title{
      margin-top: 20px;
    }
    .mapBlock{
        width:400px;       
        border: 15px solid #fff;
        box-shadow: 0px 0px 25px #93945C;
        padding-bottom:75%;
        position: relative;
        
        @include pad{
          margin:10px 10px;
          border: 10px solid #fff;
          width: clamp(300px,100%,400px);
        }
        iframe{
          width: 100%;
          height: 100%;
          position: absolute;
        }
    }
  }   
    
}

section.horizonBlock2{
  width: 100%;
  padding: 30px 0px  80px;
  background-image: url('../../public/img/background-brush2.png');
  background-size: clamp(600px,80%,1500px);
  background-repeat: no-repeat;
  background-position:center;
  position: relative;
  display: flex;  
  justify-content: center;
  height: 550px;

  @include laptop{
    background-size:1200px;
    padding-bottom:40px;
  }
  &.overflow-x {
    overflow-x: hidden;
  }

  
  .tealeaf2{
    position: absolute;
    width:clamp(100px,12vw,180px);
    z-index: 5;
    left: 0px;
    bottom: 110px;
  }
  .microphone{
    position: absolute;
    width:clamp(140px,20vw,350px);
    left:20px;
    bottom:50px;
    @include pad{
      left:-10px;
      bottom:40px;
    }
  }
  .fried-rice{
    position: absolute;
    width: clamp(200px,30vw,480px);
    right:0px;   
    bottom:100px; 
    @include pad{
      width:200px;
      bottom: auto;
      top: 0;
    }   
  }
  section.wrapper{
    display: flex;    
    justify-content: center;
    align-items: center;
    width: clamp(300px,100%,1200px);    
    padding: 50px;    
    margin: 0 auto;
    position: relative;
    &.overFlowimg{
      @include pad{
      height: 550px;
      overflow-x: hidden;
      margin-top: -70px;
     }
    }
    
    @include pad{      
      padding: 0;
    }
    .coffeebag,.teacan{
      position: absolute;
      width: clamp(160px,17vw,300px);
      left: 0px;
      bottom: 30px;
      
      @include laptop{
        bottom: 70px;
        left:30px;
      }
      @include pad{
        left: auto;
        right:-20px;
        bottom: 30px;
      }
    }
    .teacan{
      top:-20px;
      left: 100px;
      @include laptop{
        top:70px;
        left:60px;
      }
      @include pad{
        left: 10%;        
        top:0px;
      }      
    }
    .pileofcoffeebean{
      position: absolute;
      width: clamp(200px,30%,370px);
      right: -60px;
      @include pad{
        bottom: 20px;
      }
    }
    .openingInfo{
      width: 300px;
      margin-left: -30px;
      justify-content: center;
      
      @include pad{
        margin-top: 90px;
        margin-left: 0;
      }      
      p{
        font-family: 'noto Sans TC';
        line-height: 26px;
      }
      .linkButtom{
        background-color: #FFF;
        padding: 10px 20px;
        border-radius: 25px;
        color: #75762A;
        border: 2px solid #75762A;
        box-shadow: 0px 4px 0px #75762A;
        letter-spacing: 0.2rem;
        font-size: 20px;
        font-weight: 900;
        text-align: center;
        width: 180px;
        display: block;     
        margin: 20px auto;
        @include pad{
          margin: 30px 0 30px auto;
        }
        &.m_alignLeft{
          @include pad{
            margin: 30px auto 30px 0;
          }
        }   
      }
      
    }
   
  }
}





.h2Title{
  font-weight: 600;
  color:#656539;
  font-size: 28px;
  text-align: center;
  letter-spacing: 0.2rem;
  margin-bottom:15px;
  @include pad{
    font-size: 21px;
  }
}

.anchorBtn{
  position: absolute;
  bottom: 80px;
  left:200px;
  width: 140px;
  height: 140px;  
  font-size: 1.5rem;
  font-weight: 800;
  letter-spacing: 0.2rem;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
  justify-content: center;
  background: url("../../public/img/Group.png");
  background-repeat: no-repeat;
  background-size:contain;
  @include laptop{
    font-size: 1.2rem;
    left:150px;
    bottom: 0px;
    width: 110px;
    height: 110px;
  }
  @include pad{
    display: none;
  }
  img{
    display: block;
    align-self: center;
    width: 32px;
    transform: rotate(180deg);
  }
}

section.horizonBlock{ 
  background-image: url('../../public/img/background-brush.png');
  background-repeat: no-repeat;
  background-size: clamp(800px,60%,1200px);
  background-position: left bottom;
  position: relative;
  @include laptop{
    background-position: left top 50px;
  }
  .tealeaf1{
    position: absolute;
    width:5vw;
    bottom: 100px;
    left: 50px;
    @include laptop{
      width: 7vw;
      left: 10px;
      bottom: 210px;

    }
    @include pad{
     width: 70px;
     left: auto;
     bottom: 150px;
     right: 0px;
    }
  }
  section.wrapper{
    display: flex;         
    align-items: center;
    width: clamp(320px, 95% ,1300px);
    padding: 60px 0px;
    margin: 0 auto; 
    @include laptop{
      padding-bottom: 0;
      justify-content: center;
      flex-wrap: wrap;
    }   
    @include pad{   
      width: 80%; 
      flex-direction: column;
    }
    section.brandimg{ 
      z-index: 2;
      width: 550px;
      height: 400px;
      display: flex;      
      margin-left: -30px;
      justify-content: center;
      align-items: flex-end;
      background-image: url('../../public/img/indexbanner.png');
      background-size: contain;
      background-repeat: no-repeat;
      @include laptop{
        width: 60%;
        flex-wrap:nowrap;
      }  
      @include pad{
        width:100%;
        flex-wrap: wrap;
        height: 350px;
        margin-left: 0;
        background-size: 280px;
        background-position: top 40px center;       
      }
      div{
        width: 180px;
        padding: 10px 20px;
        margin: 0px 5px 30px;
        font-size: 15px;
        background:#ffffffe3;
        position: relative;
        box-shadow:0px 0px 3px #b0b075;
        border-radius: 8px;
        font-family:'noto sans TC';
        line-height: 1.5rem;
        
        @include pad{
          width: 100%;
          margin-left: 10px;
          margin-bottom: -130px;
        }
       
        &.holiday{
          margin-bottom: 130px;
          @include pad{
            margin-bottom: 20px;
          }
        }
        &::before{
          content:'快訊';
          display: flex;
          color: #fff;
          width: 30px;
          height: 60px;
          background-image: url(../../public/img/news_icon.png);
          background-size: contain;
          background-repeat: no-repeat;        
          writing-mode: vertical-lr;
          align-items: center;
          justify-content: flex-end;
          letter-spacing: 11px;        
          left: -15px;
          top:-20px;
          position: absolute;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
    section.openingInfo{
      
      h2{
        font-size: 1.5rem;
        letter-spacing: 0.3rem;
        color: #656539;  
        margin-left: 50px;
        font-weight: 600;
        margin-bottom: 5px;
        @include laptop{
          text-align: right;         
        }
        @include pad{
          font-size: 1.3rem;
        }
      } 
      div.openingInfo_frame{
        background: #ffffff8b;
        margin-left: -120px;
        padding: 20px 20px 20px 100px;
        border-top: 1px solid #93944C;
        border-bottom: 1px solid #93944C;

        @include laptop{  
         
          display: flex;        
          margin-left: 0px;
          padding:10px 20px;
          justify-content: space-between;
        }
        @include pad{
          width:auto;
          align-items: flex-start;
          flex-direction: column;
          padding:10px 25px;
        }
        div{
          margin: 15px 0px;
          display: flex;
          align-items: center;
          justify-content: end;
          @include laptop{
            margin: 15px;
            justify-content: center;
          }
          @include pad{
            margin: 10px 0;
          }
          img{            
            margin-right: 15px;            
          }
          span{
            display:block;
            width: 240px;
            line-height: 24px;
            font-family: 'noto sans TC';
            @include laptop{
              width:auto;
            }
            @include pad{
              font-size: 15px;
              max-width: 240px;
              letter-spacing: 0.5px;             
            }
          }
        }
      }
    }
  }
}


 
